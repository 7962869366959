import { defineComponent, ref } from 'vue';
import Header from '@/components/Header.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import NewsCard from '@/components/news/NewsCard.vue';
import Footer from '@/components/Footer.vue';
export default defineComponent({
  name: 'News',
  components: {
    HeaderComponent: Header,
    Breadcrumb: Breadcrumb,
    NewsCard: NewsCard,
    FooterComponent: Footer
  },
  inject: ['isMobile', 't'],
  setup: function setup() {
    var links = ref([{
      name: '首頁',
      path: '/'
    }, {
      name: '防疫快訊'
    }]);
    return {
      links: links
    };
  }
});